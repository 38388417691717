import $ from 'jquery'
import 'jquery-ui/tooltip'
import 'jquery-validation'
import BaseJQueryDialog from "./BaseJQueryDialog";
import {getLogger} from "./util/Logging";
import initialiseApplication from "../Init";

const log = getLogger('Login');

/**
 */
export default class Login extends BaseJQueryDialog {

    onReady() {
        super.onReady();
        this.template_stuff();
    }

    template_stuff() {
        if (form_errors) {
            log.info("Form errors %o", form_errors);

            $.each(form_errors, (index, error) => {
                const $element = $('#' + index);
                $element.tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                    .data("title", error)
                    .addClass("error")
                    .tooltip("show"); // Create a new tooltip based on the error message we just set in the title
            });

            if (form_errors.error_code === 4001) {
                $('#process-message').show();
                $('#process-message').removeClass('alert-success').addClass('alert-danger');
                $('#process-message strong').text('Login failed!');
                $('#process-message span').html(form_errors.msg);
            }
        }

        log.debug('Validate');
        $('#login-form').validate({
            errorElement: 'div',
            rules: {
                email: {
                    email: true
                }
            },
            messages: {
                email: "Please enter a valid email"
            },
            showErrors: (errorMap, errorList) => {
                log.debug('Validate errors %o %o', errorList, errorMap);
                const validator = $('#login-form').validate();
                // Clean up any tooltips for valid elements
                $.each(validator.validElements(), (index, element) => {
                    const $element = $(element);
                    $element.data("title", "") // Clear the title - there is no error associated anymore
                        .removeClass("error")
                        .tooltip("destroy");
                });
                // Create new tooltips for invalid elements
                $.each(errorList, (index, error) => {
                    log.warn(error);
                    const $element = $(error.element);
                    $element.tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                        .data("title", error.message)
                        .addClass("error")
                        .tooltip("show"); // Create a new tooltip based on the error message we just set in the title
                });
            },
            submitHandler: form => {
                form.submit();
            },
            highlight: element => {
                $(element).closest('.form-group').removeClass('success').addClass('has-error');
            },
            success: element => {
                element.addClass('valid')
                    .closest('.form-group').removeClass('error').addClass('has-success');
            }
        });

    }
}

// initialiseApplication({loadVersion: true, checkSessionTimeout: false});
// new Login();

