import $ from 'jquery';
import 'jquery-validation';
import 'jquery-ui/draggable';
import 'jquery-ui/droppable';
import 'jquery-ui/slider';
import 'jquery-ui/tooltip';
import "jquery-ui/ui/widgets/autocomplete";
import 'jquery-popover';
import 'jquery-ui-touch-punch/jquery.ui.touch-punch'
import 'bootstrap'
import 'bootstrap-toggle/js/bootstrap-toggle'

import UriFactory from '../UriFactory'
import {BasePage} from "./BasePage";
import {getLogger} from "./util/Logging";

const log = getLogger('BaseJQueryPage');

class BaseJQueryPage extends BasePage {
    constructor() {
        super();
    }


    onReady() {
        super.onReady();
        this.init_sidebar();
    }

    init_sidebar() {
        log.debug('Init sidebar');
        $('body').on('click', '#main-in-menu-btn, #main-menu-btn', e => {
            let $sidebar = $(".sidebar");
            $sidebar
                .toggle()
                .animate({
                    left: ($sidebar.css("left") === "0px") ? "-100px" : "0px"
                }, 350)
        });
    }

    static base64ToBlob(base64, mime) {
        mime = mime || '';
        let sliceSize = 1024;
        let byteChars = window.atob(base64);
        let byteArrays = [];

        for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            let slice = byteChars.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: mime});
    }

    standard_page() {
        log.debug('Init page');

        const $footer = $('#footer');
        const docHeight = $(window).height();
        const footerHeight = $footer.height();
        const footerTop = $footer.position().top + footerHeight;

        if (footerTop < docHeight) {
            $footer.css('margin-top', -11 + (docHeight - footerTop) + 'px');
        }


        $(document).keydown(function (event) {
            if (event.ctrlKey === true &&
                (event.which === '61' ||
                    event.which === '107' ||
                    event.which === '173' ||
                    event.which === '109' ||
                    event.which === '187' ||
                    event.which === '189')) {
                event.preventDefault();
            }
            // 107 Num Key  +
            // 109 Num Key  -
            // 173 Min Key  hyphen/underscor Hey
            // 61 Plus key  +/= key
        });

        $(window).bind('mousewheel DOMMouseScroll', event => {
            if (event.ctrlKey === true) {
                event.preventDefault();
            }
        });


        let SESSION_TIMES;

        function sessionEnds() {
            $.getJSON(UriFactory.makeUserLastSeenUrl(), rawJson => {
                SESSION_TIMES = (typeof rawJson !== undefined) ? rawJson : {};
                log.debug(SESSION_TIMES);
                if (SESSION_TIMES.expired === true) {
                    window.location.reload(true);
                }
                userTimeout();
            });
        }

        $('body').on('click', '#user-active-box .yes', function (e) {
            $('#user-active-box').hide();
            sessionEnds();
        });

        function userTimeout() {
            setTimeout(() => {
                userTimeout();
            }, 30000);

            if (SESSION_TIMES.expires_time - (Date.now() / 1000) < 300) {
                $('#user-active-box').show();
            }
        }

        sessionEnds();
    }
}

export default BaseJQueryPage;
