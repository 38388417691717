import $ from 'jquery'
import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from "../Init";
import {getLogger} from "./util/Logging";
import WebApiUtil from "../util/WebApiUtil";

const log = getLogger('UserList');

/**
 */
export default class UserList extends BaseJQueryPage {


    onReady() {
        super.onReady();
        super.init_sidebar();
        super.standard_page();
        this.template_stuff();
    }

    template_stuff() {

        /**
         *  When the user clicks on the delete user button, pop up an anti-nike dialog to
         *  confirm that the user really wants to be deleted.
         */
        $('body').on('click', '.user-del-btn', e => {
            $('#modal_user_name').text($(e.currentTarget).attr('data-user-name'));
            $('#user-del-confirm-btn')
                .attr('data-user-id', $(e.currentTarget).attr('data-user-id'))
                .attr('data-user-name', $(e.currentTarget).attr('data-user-name'));
        });

        $('#user-del-confirm-btn').click(function (e) {
            const userId = $(this).attr('data-user-id');
            const user_name = $(this).attr('data-user-name');

            WebApiUtil.deleteUser(userId)
                .then(r => {
                    $('#delete-user-modal').modal('hide');
                    $('#process-message').show();
                    $(`.users-table tr[data-user-id="${userId}"]`).remove();
                    $('#process-message').removeClass('alert-danger')
                        .addClass('alert-success');
                    $('#process-message strong').text('Deleted!');
                    $('#process-message span').html(`User <strong>${user_name}</strong> has been deleted.`);
                })
                .catch(err => {
                    log.error("Failed to delete user: %o", err);
                    $('#delete-user-modal').modal('hide');

                    $('#process-message').show();
                    $('#process-message').removeClass('alert-success').addClass('alert-danger');
                    $('#process-message strong').text('Failed!');
                    $('#process-message span').text('Could not delete user. Please wait and try again later.');
                });
        })
    }
}

