import $ from 'jquery';
import _ from 'lodash';
import {getLogger} from "../view/util/Logging";
import http from 'axios';

const log = getLogger('main');

/**
 *
 * @typedef {{expired: boolean, expires_time: Date}} SessionTimes
 */

/**
 *
 * @type {SessionTimes}
 */
let SESSION_TIMES = undefined;

export default class StandardPage {


    static sessionEnds() {
        http.get("/users/lastseen")
            .then(r => {
                /** @type {SessionTimes} */const sessionTimes = r.data;
                log.debug("Session times %o", sessionTimes);
                SESSION_TIMES = sessionTimes;
                StandardPage.xxx(sessionTimes);
                _.delay(() => StandardPage.sessionEnds(), 45000 /* ms */)
            })
            .catch(e => {
                if (e.code) {
                    log.error("ACID web server error %d: %s", e.code, e.message)
                } else {
                    log.error("ACID web server error: %s", e.message)
                }
                _.delay(() => StandardPage.sessionEnds(), 300000 /* ms */);
                StandardPage.xxx(SESSION_TIMES);
            })
    }


    static xxx(sessionTimes) {
        if (sessionTimes.expired === true) {
            log.warn('Session expired, forcing a reload');
            window.location.reload(true);
        }
        if (sessionTimes && sessionTimes.expires_time - (Date.now() / 1000) < 300) {
            $('#user-active-box').show();
        }
    }

    /**
     * @class SeverVersions
     * @property {string} frontend
     * @property {string} backend
     */

    /**
     *
     * @returns {Promise<SeverVersions>}
     */
    static getVersions() {
        return http.get('/versions')
            .then(response => {
                const versions = response.data;
                log.debug("Server versions %o", versions);
                $('#frontend').html('Frontend: v' + versions.frontend);
                $('#backend').html('Backend: v' + versions.backend);
                return versions;
            })
            .catch(e => {
                if (e.code) {
                    log.error("ACID web server error %d: %s", e.code, e.message)
                } else {
                    log.error("ACID web server error: %s", e.message)
                }
            });
    }

    /**
     * Setup Axios with a user-agent and other options that are
     *
     * @private
     * @see {@link https://github.com/axios/axios#config-defaults}
     * @see {@link https://docs.djangoproject.com/en/dev/ref/csrf/}
     */
    static _configureAxios() {
        // Until we have CORS setup, this is causing issues
        // http.defaults.headers.common["User-Agent"] = "ACID Agent v" + AcidVersion.version;

        http.defaults.xsrfCookieName = 'csrftoken';
        http.defaults.xsrfHeaderName = 'X-CSRFToken';
    }

    static init(options) {
        options = options || {loadVersion: true, checkSessionTimeout: true};

        $('body').on('click', '#user-active-box .yes', e => {
            $('#user-active-box').hide();
            StandardPage.sessionEnds();
        });

        this._configureAxios();

        if (options && options.checkSessionTimeout) {
            this.sessionEnds();
        }
        if (options && options.loadVersion) {
            this.getVersions();
        }
    }
}