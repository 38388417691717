import 'jquery-popover'
import {getLogger} from './util/Logging';
import BaseJQueryPage from './BaseJQueryPage';

const log = getLogger('Dashboard');

export default class Dashboard extends BaseJQueryPage {

    onReady() {
        log.debug('Loading dashboard...');
        super.onReady();

        this.init_sidebar();
        this.standard_page();
    }
}




