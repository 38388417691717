import initialiseApplication from '../Init';
import Login from './Login';
import Dashboard from './Dashboard';
import UserList from './UserList';
import UserEdit from './UserEdit';
import NewUser from './NewUser';
import UserEditOther from './UserEditOther';
import AdminEditPermissions from './AdminEditPermissions';
import AdminEditRoles from './AdminEditRoles';
import AdminEditSubscriptions from './AdminEditSubscriptions';
import NewOrganisation from './NewOrganisation';
import OrganisationEdit from './OrganisationEdit';
import OrganisationList from './OrganisationList';
import SimplePage from './SimplePage';

/**
 * This one of about three top level entry points for the ACID web
 * client javascript.
 *
 * Instead of having one entry point per page, this single entry point
 * is used with a global variable set in a javascript block prior to the
 * inclusion of this script. This workaround means the webpack build needs
 * to built significantly less code. In theory the webpack configuration
 * could have been setup to mimick this behavior, however this is very quick
 * and easy way to generate all but the 3d viewer (3js) without any rocket
 * science involved.
 *
 * Note: pages that require 3js must not be vectored through this module as it
 * will bloat all other non-3js pages.
 *
 * see
 *   - webpack.config.js
 */

switch (MULTI_VIEW) {
    case 'simple-page':
        initialiseApplication();
        new SimplePage();
        break;

    case 'login':
        initialiseApplication({loadVersion: true, checkSessionTimeout: false});
        new Login();
        break;

    case 'dashboard':
        initialiseApplication();
        new Dashboard();
        break;

    //
    //  User
    //

    case 'new-user':
        initialiseApplication();
        new NewUser();
        break;

    case 'user-list':
        initialiseApplication();
        new UserList();
        break;

    case 'user-edit':
        initialiseApplication();
        new UserEdit();
        break;

    case 'user-edit-other':
        initialiseApplication();
        new UserEditOther();
        break;

    //
    // Admin
    //
    case 'admin-edit-permissions':
        initialiseApplication();
        new AdminEditPermissions();
        break;

    case 'admin-edit-roles':
        initialiseApplication();
        new AdminEditRoles();
        break;

    case 'admin-edit-subscriptions':
        initialiseApplication();
        new AdminEditSubscriptions();
        break;

    case 'new-organisation':
        initialiseApplication();
        new NewOrganisation();
        break;

    case 'organisation-edit':
        initialiseApplication();
        new OrganisationEdit();
        break;

    case 'organisation-list':
        initialiseApplication();
        new OrganisationList();
        break;

    default:
        console.log('View not configured');
}
