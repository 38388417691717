/*
* This documentation is just for quick reference. The canonical location is found in the django url routing
*/

class UriFactory {

    /**
     * @return {string}
     */
    static makeUserLastSeenUrl() {
        return '/users/lastseen';
    }

    /**
     * @return {string}
     */
    static makeListUsersViewUrl() {
        return '/users/manage';
    }

    /**
     *
     * @param {string|number} userId the user to be deleted.
     * @return {string} the url
     */
    static makeDeleteUserUrl(userId) {
        return `/user/${userId}/delete`;
    }

    /**
     * Used to get acid-web and acid-master versions
     * @return {string}
     */
    static makeVersionsUrl() {
        return '/versions'
    }

    //
    // Users urls
    //

    /**
     * This url is found in the surgeon profile page, where they can add/remove assistants
     * @return {string}
     */
    static makeUsersEditOtherUrl() {
        return '/users/edit-other'
    }


    //
    // Super Admin pages urls
    //

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminPermissionUpdateUrl() {
        return '/admin/permission/update'
    }

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminPermissionAddUrl() {
        return '/admin/permission/add'
    }

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminRolesUrl() {
        return '/admin/roles'
    }

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminGetUserRolesProductsUrl() {
        return '/admin/get_user_roles_products'
    }

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminGetRolesUrl() {
        return '/admin/get_roles'
    }

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminUserSubscriptionsAddUrl() {
        return '/admin/user_subscriptions/add'
    }

    /**
     * Only available to super admin
     * @return {string}
     */
    static makeAdminUserSubscriptionsRemoveUrl() {
        return '/admin/user_subscriptions/remove'
    }

}

export default UriFactory
