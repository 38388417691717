import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from "../Init";

/**
 * A simple page (e.g. a dialog) without the side part
 * or other authenticated accoutrement.
 */
export default class SimplePage extends BaseJQueryPage {

}

