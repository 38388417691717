import $ from 'jquery';
import 'jquery-ui/tooltip'
import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from "../Init";
import {getLogger} from "./util/Logging";

const log = getLogger('NewOrganisation');

/**
 */
export default class NewOrganisation extends BaseJQueryPage {


    onReady() {
        super.onReady();
        this.template_stuff();
    }

    template_stuff() {
        log.debug('TODO: fix form error handling');

        if (form_errors) {
            $.each(form_errors, function (index, error) {
                log.debug(index);
                log.debug(error);
                if (index === 'captcha') {
                    index = 'g-recaptcha';
                }
                const $element = $('#' + index);
                $element.tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                    .data("title", error)
                    .addClass("error")
                    .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
            });
        }

        $('#organization-form').validate({
            errorElement: 'div',
            rules: {
                email: {
                    email: true
                },
                name: {
                    minlength: 1
                }
            },
            messages: {
                email: "Please enter a valid email",
                name: "Please enter the name of your organization"
            },
            showErrors:(errorMap, errorList) => {
                log.debug('Validate errors %o %o', errorList, errorMap);
                const validator = $('#organization-form').validate();
                // Clean up any tooltips for valid elements
                $.each(validator.validElements(), (index, element) => {
                    const $element = $(element);
                    $element.data("title", "") // Clear the title - there is no error associated anymore
                        .removeClass("error")
                        .tooltip("destroy");
                });
                // Create new tooltips for invalid elements
                $.each(errorList, function (index, error) {
                    log.debug(error);
                    const $element = $(error.element);
                    $element.tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                        .data("title", error.message)
                        .addClass("error")
                        .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
                });
            },
            submitHandler: function (form) {
                form.submit();
            },
            highlight: function (element) {
                $(element).closest('.form-group').removeClass('success').addClass('has-error');
            },
            success: function (element) {
                element.addClass('valid')
                    .closest('.form-group').removeClass('error').addClass('has-success');
            }
        });

    }
}


