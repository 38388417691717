import $ from 'jquery'
import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from "../Init";
import {getLogger} from "./util/Logging";
import UriFactory from "../UriFactory";
import http from "axios";

const log = getLogger('AdminEditSubscriptions');

/**
 */
export default class AdminEditSubscriptions extends BaseJQueryPage {


    onReady() {
        super.onReady();
        this.do_stuff();
    }

    do_stuff() {
        let data = {
            'user_id': '',
            'product_id': '',
            'subscription_id': '',
            'role_id': '',
            'service_usages': ''
        };
        const data_all = {
            'user': '',
            'product_name': '',
            'subscription_type': '',
            'role_name': '',
            'service_usages': ''
        };
        const data_remove = {
            'user_id': '',
            'product_id': '',
            'role_id': '',
        };

        function clear_selection() {
            $('.product-container').hide();
            $('#product').val('').change();
            $("#product option").show();

            $('.subscription-container').hide();
            $('#subscription').val('').change();

            $('.role-container').hide();
            $('#role').val('').change();

            $('.roles-products-table tbody tr').remove();
            $('.roles-products-table-container').hide();
        }

        $('#user').on('change', e => {
            data.user_id = e.target.value;

            clear_selection();

            if (data.user_id !== '') {
                data_all.user = $('option[value="' + data.user_id + '"]', e.target).text();

                $('.product-container').show();
                $('.roles-products-table-container').show();

                http.post(UriFactory.makeAdminGetUserRolesProductsUrl(), data)
                    .then((response) => {
                            log.debug("POST response %o", response);

                            if (response.status === 200 && response.data.status.toLowerCase() === 'done') {
                                $('.roles-products-table tbody tr').remove();

                                for (let i = 0; i < response.data.roles_products.length; i++) {

                                    const productId = response.data.roles_products[i].product_id;
                                    const roleId = response.data.roles_products[i].id;
                                    const productName = response.data.roles_products[i].product_name;
                                    const roleName = response.data.roles_products[i].name;

                                    $("#product option[value=" + productId + "]").hide();

                                    $('.roles-products-table tbody').append(
                                        `<tr data-role-permission-ids="${roleId}_${productId}">
                                            <td>${productName}</td>
                                            <td>${roleName}</td>
                                            <td>
                                                <span class="rp-remove-btn btn btn-danger btn-xs pull-right" 
                                                data-product-name="${productName}" 
                                                data-role-permission-ids="${roleId}_${productId}">Remove</span>
                                            </td>
                                        </tr>`
                                    )
                                }
                            }

                        }
                    )
                    .catch(err => {
                        log.error("Failed to get user products %o", err)
                    });
            }
        });

        $('#product').on('change', e => {
            data.product_id = e.target.value;

            $('#role').val('').change();
            $('.role-container').hide();

            if (data.product_id !== '') {
                data_all.product_name = $('option[value="' + data.product_id + '"]', e.target).text();

                $('#service-usage').val('').change();
                $('.service-usage-container').show();

                let service_usages = $('option[value="' + data.product_id + '"]', e.target).attr('data-service-usages').split(',');

                $("#service-usage option").remove();

                $('#service-usage').append($("<option></option>").attr("value", "").text("--"));
                for (let i = 0; i < service_usages.length; i++) {
                    $('#service-usage').append($("<option></option>").attr("value", service_usages[i]).text(service_usages[i]));
                }

            } else {
                $('.service-usage-container').hide();
                $('#service-usage').val('').change();
            }
        });

        $('#service-usage').on('change', e => {
            data.service_usages = e.target.value;

            $('#role').val('').change();
            $('.role-container').hide();

            if (data.service_usages !== '') {
                data_all.service_usages = data.service_usages;

                $('#subscription').val('').change();
                $('.subscription-container').show();

            } else {
                $('.subscription-container').hide();
                $('#subscription').val('').change();
            }
        });

        $('#subscription').on('change', e => {
            data.subscription_id = e.target.value;

            $('#role').val('').change();
            $("#role option").remove();
            $('.role-container').hide();

            if (data.subscription_id !== '') {
                // $('.user-product-add-btn').show();
                data_all.subscription_type = $('option[value="' + data.subscription_id + '"]', e.target).text();

                $('#role').val('').change();
                $('.role-container').show();

                http.post(UriFactory.makeAdminGetRolesUrl(), data)
                    .then((response) => {
                            log.debug("POST response %o", response);

                            if (response.status === 200 && response.data.status.toLowerCase() === 'done') {
                                $('#role').append($("<option></option>")
                                    .attr("value", "")
                                    .text("--"));

                                for (let i = 0; i < response.data.roles.length; i++) {

                                    $('#role')
                                        .append($("<option></option>")
                                            .attr("value", response.data.roles[i][0])
                                            .text(response.data.roles[i][1]));
                                }
                            }

                        }
                    )
                    .catch(err => {
                        log.error("Failed to get product roles %o", err)
                    });


            } else {
                // $('.user-product-add-btn').hide();
            }
        });

        $('#role').on('change', e => {
            data.role_id = e.target.value;

            $('.user-product-add-btn').hide();

            if (data.role_id !== '') {
                data_all.role_name = $('option[value="' + data.role_id + '"]', e.target).text();
                $('.user-product-add-btn').show();
            }

        });

        $('body').on('click', '.user-product-add-btn', e => {
            let $modal = $('#add-user-product-modal');

            $('span[data-user-name=""] strong', $modal).text(data_all.user);
            $('span[data-product-name=""] strong', $modal).text(data_all.product_name);
            $('span[data-role-name=""] strong', $modal).text(data_all.role_name);
            $('span[data-subscription-type=""] strong', $modal).text(data_all.subscription_type);

            log.debug(data);

        });

        $('#user-product-add-confirm-btn').click( () => {

            log.debug(data);

            http.post(UriFactory.makeAdminUserSubscriptionsAddUrl(), data)
                .then((response) => {
                        $('#add-user-product-modal').modal('hide');

                        if (response.status === 200 && response.data.status.toLowerCase() === 'done') {
                            $('#process-message')
                                .removeClass('alert-danger').addClass('alert-success');
                            $('#process-message strong')
                                .text('Done!');
                            $('#process-message span')
                                .html(`User ${data_all.user} has been added to product ${data_all.product_name}.`);
                        } else {
                            $('#process-message')
                                .removeClass('alert-success').addClass('alert-danger');
                            $('#process-message strong')
                                .text('Failed!');
                            $('#process-message span')
                                .text(`Could not add new user subscription. Error: "${response.data.message}"`);
                        }

                        $('#process-message').show();
                        $('#process-message').delay(7000).slideUp();

                        clear_selection();
                        $('#user').val('').change();

                        data = {
                            'user_id': '',
                            'product_id': '',
                            'subscription_id': '',
                            'role_id': '',
                            'service_usages': ''
                        };

                    }
                )
                .catch(err => {
                    log.error("Failed to add user to product %o", err)
                });
        });


        $('body').on('click', '.rp-remove-btn', e => {

            let pid = $(e.target).attr('data-role-permission-ids').split('_')[1],
                rid = $(e.target).attr('data-role-permission-ids').split('_')[0],
                product_name = $(e.target).attr('data-product-name');

            data_remove.user_id = data.user_id;
            data_remove.product_id = pid;
            data_remove.role_id = rid;

            let $modal = $('#remove-rp-modal');

            $('span[data-user-name=""] strong', $modal).text(data_all.user);
            $('span[data-product-name=""] strong', $modal).text(product_name);

            $('#remove-rp-modal').modal('show');
        });

        $('body').on('click', '#user-product-remove-confirm-btn', () => {

            http.post(UriFactory.makeAdminUserSubscriptionsRemoveUrl(), data_remove)
                .then((response) => {
                        $('#remove-rp-modal').modal('hide');

                        if (response.status === 200 && response.data.status.toLowerCase() === 'done') {
                            const roleId = data_remove.role_id;
                            const productId = data_remove.product_id;

                            $('.roles-products-table tr[data-role-permission-ids="' + roleId + '_' + productId + '"]')
                                .remove();
                            $('#user').val('').change();

                            $('#process-message').removeClass('alert-danger').addClass('alert-success');
                            $('#process-message strong').text('Done!');
                            $('#process-message span').html('User subscription has been successfully removed.');
                        } else {
                            $('#process-message').removeClass('alert-success').addClass('alert-danger');
                            $('#process-message strong').text('Failed!');
                            $('#process-message span').text('Could not remove user subscription.');
                        }

                        $('#process-message').show();
                        $('#process-message').delay(7000).slideUp();

                        data_remove.user_id = '';
                        data_remove.product_id = '';
                        data_remove.role_id = '';
                    }
                )
                .catch(err => {
                    log.error("Failed to remove user from product %o", err)
                });
        });

    }
}

