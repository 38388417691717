import formusVersion from "raw-loader!../../VERSION";

/**
 * Provide the semantic version number (as a string) for the software.
 *
 * This version will be set by the build pipeline in deployment environments.
 */
class AcidVersion {

    /**
     * @returns {string}
     */
    static get version() {
        return formusVersion;
    }
}

export default AcidVersion;
