import $ from 'jquery';
import 'jquery-validation';
import 'jquery-ui/draggable';
import 'jquery-ui/droppable';
import 'jquery-ui/slider';
import 'jquery-ui/tooltip';
import 'jquery-ui/autocomplete';
import 'jquery-popover';
import 'jquery-ui-touch-punch/jquery.ui.touch-punch'

import 'bootstrap'



import {BasePage} from "./BasePage";

/**
 * A base for simple dialog pages
 */
class BaseJQueryDialog extends BasePage {


    onReady() {
        super.onReady();
        this.initialise();
    }

    /**
     * This will setup the base _layout_plain.html template.
     */
    initialise() {
        var docHeight = $(window).height(),
            footerHeight = $('#footer').outerHeight(),
            footerTop = $('#footer').position().top + footerHeight;

        if (footerTop < docHeight) {
            $('#footer').css('margin-top', 0 + (docHeight - footerTop) + 'px');
        }
    }
}

export default BaseJQueryDialog;