import $ from "jquery";
import _ from "lodash";
import 'bootstrap'

import AcidVersion from './AcidVersion';
import {getLogger} from "./util/Logging";

import 'jquery-ui-css/slider.css';
import 'jquery-ui-css/all.css';
import 'jquery-ui-themes/themes/smoothness/jquery-ui.css'
import 'jquery-ui-themes/themes/smoothness/theme.css'
import 'jquery-popover/dist/jquery-popover-0.0.3.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-toggle/js/bootstrap-toggle'
import 'bootstrap-toggle/css/bootstrap-toggle.css'
import 'font-awesome/css/font-awesome.css'
import '../../css/formuslabs.scss'


const log = getLogger('BasePage');

export class BasePage {
    constructor() {
        log.info("ACID v%s, jquery %s, lodash %s", AcidVersion.version, $().jquery, _.VERSION);
        $(document).ready(() => {
            this.onReady();
        });
    }

    /**
     * A virtual that the derived classed can hook jQuery on document ready events.
     */
    onReady() {
    }
}

export default BasePage;
