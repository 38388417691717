import $ from 'jquery'
import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from '../Init'
import {getLogger} from "./util/Logging";
import UriFactory from "../UriFactory";

const log = getLogger('AdminEditPermissions');

/**
 */
export default class AdminEditPermissions extends BaseJQueryPage {


    onReady() {
        super.onReady();
        this.do_stuff();
    }

    do_stuff() {
        $(document).ready(function () {

            $('body')
                .on('click', '.pm-edit-btn, .pm-calcel-btn', (e) => {
                    var data_id = $(this.currentTarget).attr('data-permissions-id'),
                        $parent_tr = '.permissions-table tr[data-permissions-id="' + data_id + '"]',
                        name_org = $('.readonly-text', $parent_tr).text();

                    $('input', $parent_tr).val(name_org);
                    $('.edit-fields, .readonly-text', $parent_tr).toggle();
                })

                .on('click', '.pm-update-btn', (e) => {
                    var $parent_tr = $(this.currentTarget).closest('tr'),
                        data_id = $parent_tr.attr('data-permissions-id'),
                        name = $('input', $parent_tr).val(),
                        data = {
                            'csrfmiddlewaretoken': '{{csrf_token}}',
                            'id': data_id,
                            'name': name
                        };

                    log.debug(data);

                    $.ajax({
                        url: UriFactory.makeAdminPermissionUpdateUrl(),
                        data: data,
                        type: 'POST',
                        success: function (response) {
                            log.debug(response);
                            $('.readonly-text', $parent_tr).text(name);
                            $('input', $parent_tr).val(name);
                            $('.edit-fields, .readonly-text', $parent_tr).toggle();

                            if (response.status === "done") {
                                $('.feedback-table tr[data-feedback-id="' + data['feedback_id'] + '"]').remove();

                                $('#process-message').removeClass('alert-danger').addClass('alert-success');
                                $('#process-message strong').text('Done!');
                                $('#process-message span').html('Permission with ID <strong>' + data_id + '</strong> has been updated.');
                            } else {
                                $('#process-message').removeClass('alert-success').addClass('alert-danger');
                                $('#process-message strong').text('Failed!');
                                $('#process-message span').text('Could not update permission detail. Please wait and try again later.');
                            }

                            $('#process-message').show();
                            $('#process-message').delay(7000).slideUp();
                        }
                    });

                });


            $('#pm-add-confirm-btn').click(e => {
                var name = $('#add-pm-modal input').val(),
                    data = {
                        'csrfmiddlewaretoken': '{{csrf_token}}',
                        'name': name
                    };

                log.debug(data);

                $.ajax({
                    url: UriFactory.makeAdminPermissionAddUrl(),
                    data: data,
                    type: 'POST',
                    success: function (response) {
                        log.debug(response);

                        $('#add-pm-modal').modal('hide');

                        $('#add-pm-modal input').val('');


                        if (response.status === "done") {

                            $('.permissions-table tr:last').after(
                                '<tr data-permissions-id="' + response.id + '">' +
                                '<td>' + response.id + '</td>' +
                                '<td class="permissions-table-name">' +
                                '<span class="readonly-text">' + name + '</span> ' +
                                '<input class="edit-fields" type="text-align" name="name" value="' + name + '" style="display: none;"> ' +
                                '<span class="edit-fields pm-update-btn btn btn-primary btn-xs" style="display: none;">Update</span> ' +
                                '<span class="edit-fields pm-calcel-btn btn btn-default btn-xs" style="display: none;" data-permissions-id="' + response.id + '">Cancel</span> ' +
                                '</td>' +
                                '<td>' +
                                '<span class="pm-edit-btn action-btn" data-toggle="modal" data-target="#edit-pm-modal" data-permissions-id="' + response.id + '"><i class="fa fa-pencil-square-o" title="Edit Permission"></i></span>' +
                                '</td>' +
                                '</tr>'
                            );

                            $('#process-message').removeClass('alert-danger').addClass('alert-success');
                            $('#process-message strong').text('Done!');
                            $('#process-message span').html('Your new permission has been added.');
                        } else {
                            $('#process-message').removeClass('alert-success').addClass('alert-danger');
                            $('#process-message strong').text('Failed!');
                            $('#process-message span').text('Could not add new permission. Please wait and try again later.');
                        }

                        $('#process-message').show();
                        $('#process-message').delay(7000).slideUp();
                    }
                });
            })

        });
    }
}

