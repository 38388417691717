import {getLogger, setupLogging} from "./view/util/Logging";
import StandardPage from "./util/StandardPage";

/**
 * Provide a common place to perform top level initialisation
 * of the programs (i.e. each template based django page).
 */
function initialiseApplication(options) {
    setupLogging();
    const log = getLogger('main');
    log.debug('Logging configured');

    window.onerror = (message, source, lineno, colno, error) => {
        log.error('Last chance error %s:%s[%s] : %s %o', source, lineno, colno, message, error);
    };

    StandardPage.init(options);
}

export default initialiseApplication;