import * as ulog from "ulog";
import AcidVersion from '../AcidVersion';

export function setupLogging() {
    ulog.level = getLogLevel();
}

/**
 * Determine the level of logging to use.
 *
 * The default for ulog is 'WARN'. This is useful for deployed
 * situations. For developer situation we probably want to take the
 * performance hit and get a little more logging. This logs can be filtered
 * with the browser debugger.
 *
 */
function getLogLevel() {
    if (AcidVersion.version === '0.0.0') {
        return ulog.TRACE;
    } else {
        return ulog.WARN;
    }
}

/**
 * This provides a small shim over the logging framework so we can
 * add a different logger.
 *
 * @param {string} name
 * @returns {Logger}
 *
 * @see {@link https://github.com/download/ulog}
 * @see {@link https://www.npmjs.com/package/ulog}
 */
export function getLogger(name) {
    return ulog(name);
}

/**
 * The logging class is the API provided for performing logging.
 *
 * Other functions are supported by ulog, but this gives us a rich enough
 * set of logging options without baking it a dependency on any specific
 * logging framework.
 *
 * @class Logger
 * @property {function(string, ...)} trace
 * @property {function(string, ...)} debug
 * @property {function(string, ...)} info
 * @property {function(string, ...)} warn
 * @property {function(string, ...)} error
 */