import $ from 'jquery'
import 'jquery-ui/tooltip';
import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from "../Init";
import {getLogger} from "./util/Logging";

const log = getLogger('NewUser');

/**
 * The login dialog
 */
export default class NewUser extends BaseJQueryPage {

    onReady() {
        super.onReady();
        this.do_stuff();
    }

    do_stuff() {
        if (form_errors) {
            $.each(form_errors, (index, error) => {
                log.debug(index);
                log.debug(error);
                if (index === 'captcha') {
                    index = 'g-recaptcha';
                }
                let $element = $('#' + index);
                $element.tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                    .data("title", error)
                    .addClass("error")
                    .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
            });
        }

        $('#users-form').validate({
            errorElement: 'div',
            rules: {
                email: {
                    email: true
                },
                password: {
                    minlength: 5
                },
                confirm_password: {
                    minlength: 5,
                    equalTo: "#password"
                }
            },
            messages: {
                password: {
                    required: "Please provide a password",
                    minlength: "Your password must be at least 5 characters long"
                },
                confirm_password: {
                    required: "Please provide a password",
                    minlength: "Your password must be at least 5 characters long",
                    equalTo: "Passwords do not match"
                },
                email: "Please enter a valid email"
            },
            showErrors: (errorMap, errorList) => {
                log.debug('Validate errors %o %o', errorList, errorMap);
                const validator = $('#users-form').validate();
                // Clean up any tooltips for valid elements
                $.each(validator.validElements(), (index, element) => {
                    const $element = $(element);
                    $element
                        .data("title", "") // Clear the title - there is no error associated anymore
                        .removeClass("error")
                        .tooltip("destroy");
                });
                // Create new tooltips for invalid elements
                $.each(errorList, (index, error) => {
                    log.debug(error);
                    const $element = $(error.element);
                    $element.tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                        .data("title", error.message)
                        .addClass("error")
                        .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
                });
            },
            submitHandler: form => {
                form.submit();
            },
            highlight: element => {
                $(element).closest('.form-group').removeClass('success').addClass('has-error');
            },
            success: element => {
                element.addClass('valid')
                    .closest('.form-group').removeClass('error').addClass('has-success');
            }
        });

    }
}

