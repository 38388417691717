import http from 'axios';
import UriFactory from '../UriFactory';

/**
 * Most of the API calls on the web projects return a JSON response of this form.
 * @class FormusLabsApiStatus
 * @property {string} status
 * @property {string} message
 */

/**
 * Most of the API calls on the web projects return a JSON response of this form.
 * @class FormusLabsApiCupStatus
 * @implements FormusLabsApiStatus
 * @property {Objecty<string, Cup>} cups
 */


export default class WebApiUtil {

    /**
     * Delete a user. This requires the caller to have modify user permissions.
     *
     * @param {string|number} userId
     * @return {Promise<>}
     */
    static deleteUser(userId) {
        return http.post(UriFactory.makeDeleteUserUrl(userId))
            .then(response => {
                if (response.data && response.data.status === 'done') {
                    return response.data;
                }
                return Promise.reject('Unable to delete user');
            });

    }
}
