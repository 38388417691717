import BaseJQueryPage from './BaseJQueryPage';
import {getLogger} from './util/Logging';

const log = getLogger('AdminEditRoles');

/**
 */
export default class AdminEditRoles extends BaseJQueryPage {

    onReady() {
        super.onReady();
    }
}

