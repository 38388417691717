import $ from 'jquery'
import BaseJQueryPage from './BaseJQueryPage';
import {getLogger} from './util/Logging';
import UriFactory from '../UriFactory';
import http from 'axios';

const log = getLogger('UsedEditOther');

/**
 */
export default class UserEditOther extends BaseJQueryPage {


    onReady() {
        super.onReady();
        super.init_sidebar();
        super.standard_page();
        this.template_stuff();
    }

    template_stuff() {
        if ($('.nav-tabs').length > 0) { // if .nav-tabs exists
            const hashtag = window.location.hash;

            if (hashtag !== '') {
                $('.nav-tabs > li').removeClass('active');
                $('.nav-tabs > li > a[href="' + hashtag + '"]').parent('li').addClass('active');
                $('.tab-content > div').removeClass('active');
                $(hashtag).addClass('active');
            }
        }

        // populate the list of selected surgeon assistants
        for (let i = 0; i < selected_assistants.length; i++) {
            const u_id = selected_assistants[i];
            const $avail_assist = $('#available-assistants li[data-user-id="' + u_id + '"]');
            const user = UserEditOther.getUserFromDOMAttributes($('.add-assistant', $avail_assist));

            $('#selected-assistants')
                .append(UserEditOther.makeRemovableAssistantListRow(u_id, user.firstName, user.lastName));

            $avail_assist.remove();
        }

        // populate the list of selected surgeon sales reps
        for (let i = 0; i < selected_sales_reps.length; i++) {
            const u_id = selected_sales_reps[i];
            const $avail_assist = $('#available-sales-reps li[data-user-id="' + u_id + '"]');
            const user = UserEditOther.getUserFromDOMAttributes($('.add-sales-rep', $avail_assist));

            $('#selected-sales-reps')
                .append(UserEditOther.makeRemovableSalesRepListRow(u_id, user.firstName, user.lastName));

            $avail_assist.remove();
        }

        // Add user to the selected assistants list
        $('body').on('click', '#available-assistants .add-assistant', function () {
            const user = UserEditOther.getUserFromDOMAttributes($(this));

            $('#available-assistants li[data-user-id="' + user.uid + '"]').remove();

            $('#selected-assistants')
                .append(UserEditOther.makeRemovableAssistantListRow(user.uid, user.firstName, user.lastName));

            selected_assistants.push(parseInt(user.uid));
            log.debug(selected_assistants);
        });

        // Add user to the selected sales reps list
        $('body').on('click', '#available-sales-reps .add-sales-rep', function () {
            const user = UserEditOther.getUserFromDOMAttributes($(this));

            $('#available-sales-reps li[data-user-id="' + user.uid + '"]').remove();

            $('#selected-sales-reps')
                .append(UserEditOther.makeRemovableSalesRepListRow(user.uid, user.firstName, user.lastName));

            selected_sales_reps.push(parseInt(user.uid));
            log.debug(selected_sales_reps);
        });

        // Add user to the available assistants list
        $('body').on('click', '#selected-assistants .remove-assistant', function () {
            const user = UserEditOther.getUserFromDOMAttributes($(this));

            $('#selected-assistants li[data-user-id="' + user.uid + '"]').remove();

            $('#available-assistants')
                .append(UserEditOther.makeSelectableAssistantListRow(user.uid, user.firstName, user.lastName));

            selected_assistants.splice(selected_assistants.indexOf(parseInt(user.uid)), 1);
            log.debug(selected_assistants);
        });

        // Add user to the available sales reps list
        $('body').on('click', '#selected-sales-reps .remove-sales-rep', function () {

            const user = UserEditOther.getUserFromDOMAttributes($(this));

            $('#selected-sales-reps li[data-user-id="' + user.uid + '"]').remove();

            $('#available-sales-reps')
                .append(UserEditOther.makeSelectableSalesRepListRow(user.uid, user.firstName, user.lastName));

            selected_sales_reps.splice(selected_sales_reps.indexOf(parseInt(user.uid)), 1);
            log.debug(selected_sales_reps);
        });

        // Save the selected surgeon assistants
        $('body').on('click', '#assit-save-btn', function (e) {
            const data = {
                uid: uid,
                selected_assistants: selected_assistants, //.toString(),
            };

            http.post(UriFactory.makeUsersEditOtherUrl(), data)
                .then(response => {
                    log.debug('%o', response);

                    if (response.data.status === 'done') {
                        $('#process-message').removeClass('alert-danger').addClass('alert-success');
                        $('#process-message strong').text('Done!');
                        $('#process-message span').html('Surgeon assistants selection has been successfully saved.');
                    } else {
                        $('#process-message').removeClass('alert-success').addClass('alert-danger');
                        $('#process-message strong').text('Failed!');
                        $('#process-message span').text('Surgeon assistants selection could not be saved. Please try again later.');
                    }

                    $('#process-message').show();
                    $('#process-message').delay(7000).slideUp();
                })
                .catch(err => {
                    log.error('Failed to save surgeon assistants selection: %o', err);
                });
        });

        // Save the selected surgeon sales reps
        $('body').on('click', '#sales-reps-save-btn', function (e) {
            const data = {
                uid: uid,
                selected_sales_reps: selected_sales_reps,
            };

            http.post(UriFactory.makeUsersEditOtherUrl(), data)
                .then(response => {
                    log.debug('%o', response);

                    if (response.data.status === 'done') {
                        $('#process-message').removeClass('alert-danger').addClass('alert-success');
                        $('#process-message strong').text('Done!');
                        $('#process-message span').html('Sales representatives selection has been successfully saved.');
                    } else {
                        $('#process-message').removeClass('alert-success').addClass('alert-danger');
                        $('#process-message strong').text('Failed!');
                        $('#process-message span').text('Sales representatives selection could not be saved. Please try again later.');
                    }

                    $('#process-message').show();
                    $('#process-message').delay(7000).slideUp();
                }).catch(err => {
                log.error('Failed to save surgeon sales representatives selection: %o', err);
            });

        });
    }

    /**
     * Get the relevant user information from the data attributes
     */
    static getUserFromDOMAttributes($selector) {
        return {
            uid: $selector.attr('data-user-id'),
            firstName: $selector.attr('data-user-fname'),
            lastName: $selector.attr('data-user-lname')
        };
    }

    /**
     * Make an HTML list item (<li></li>) for selecting a sales rep user
     */
    static makeSelectableSalesRepListRow(userId, firstName, lastName) {
        return UserEditOther.makeUserListRow(
            userId,
            firstName,
            lastName,
            'fa-plus-circle add-sales-rep add-user',
            'Add sales rep');
    }

    /**
     * Make an HTML list item (<li></li>) for removing a sales rep user
     */
    static makeRemovableSalesRepListRow(userId, firstName, lastName) {
        return UserEditOther.makeUserListRow(
            userId,
            firstName,
            lastName,
            'fa-minus-circle remove-sales-rep remove-user',
            'Remove sales rep');
    }

    /**
     * Make an HTML list item (<li></li>) for selecting a surgeon assistant user
     */
    static makeSelectableAssistantListRow(userId, firstName, lastName) {
        return UserEditOther.makeUserListRow(
            userId,
            firstName,
            lastName,
            'fa-plus-circle add-assistant add-user',
            'Add assistant');
    }

    /**
     * Make an HTML list item (<li></li>) for removing a surgeon assistant user
     */
    static makeRemovableAssistantListRow(userId, firstName, lastName) {
        return UserEditOther.makeUserListRow(
            userId,
            firstName,
            lastName,
            'fa-minus-circle remove-assistant remove-user',
            'Remove assistant');
    }

    /**
     * Make an HTML list item (<li></li>) given some user data, style class(s), and title
     */
    static makeUserListRow(userId, firstName, lastName, styleClass, title) {
        return `<li data-user-id="${userId}">
                    ${firstName} ${lastName} 
                    <i data-user-id="${userId}"
                       data-user-fname="${firstName}"
                       data-user-lname="${lastName}"
                       class="fa ${styleClass} pull-right"
                       title="${title}">
                    </i>
                </li>`;
    }
}

