import $ from 'jquery'
import 'jquery-ui/tooltip';
import BaseJQueryPage from './BaseJQueryPage';
import {getLogger} from './util/Logging';

const log = getLogger('UserEdit');

/**
 * The login dialog
 */
export default class UserEdit extends BaseJQueryPage {


    onReady() {
        super.onReady();
        super.init_sidebar();
        super.standard_page();
        this.template_stuff();
    }

    template_stuff() {
        log.debug("TODO: error handling");

        if (form_errors) {
            $.each(form_errors, function (index, error) {
                log.error(index);
                log.debug(error);
                let $element = $('#' + index);
                $element
                    .tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                    .data("title", error)
                    .addClass("error")
                    .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
            });
        }

        $('#users-form').validate({
            errorElement: 'div',
            rules: {
                email: {
                    email: true
                }
            },
            messages: {
                email: "Please enter a valid email"
            },
            showErrors: (errorMap, errorList) => {
                log.debug('Validate errors %o %o', errorList, errorMap);
                const validator = $('#users-form').validate();
                // Clean up any tooltips for valid elements
                $.each(validator.validElements(), (index, element) => {
                    const $element = $(element);
                    $element
                        .data("title", "") // Clear the title - there is no error associated anymore
                        .removeClass("error")
                        .tooltip("destroy");
                });
                // Create new tooltips for invalid elements
                $.each(errorList, (index, error) => {
                    log.warn(error);
                    const $element = $(error.element);
                    $element
                        .tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                        .data("title", error.message)
                        .addClass("error")
                        .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
                });
            },
            submitHandler: function (form) {
                form.submit();
            },
            highlight: function (element) {
                $(element)
                    .closest('.form-group')
                    .removeClass('success')
                    .addClass('has-error');
            },
            success: function (element) {
                element
                    .addClass('valid')
                    .closest('.form-group')
                    .removeClass('error')
                    .addClass('has-success');
            }
        });

        $('#user-pwd-form').validate({
            errorElement: 'div',
            rules: {
                password: {
                    minlength: 5
                },
                confirm_password: {
                    minlength: 5,
                    equalTo: "#password"
                },
            },
            messages: {
                password: {
                    required: "Please provide a password",
                    minlength: "Your password must be at least 5 characters long"
                },
                confirm_password: {
                    required: "Please provide a password",
                    minlength: "Your password must be at least 5 characters long",
                    equalTo: "Passwords do not match"
                }
            },
            showErrors: (errorMap, errorList) => {
                log.debug('Validate errors %o %o', errorList, errorMap);
                const validator = $('#user-pwd-form').validate();
                // Clean up any tooltips for valid elements
                $.each(validator.validElements(), (index, element) => {
                    const $element = $(element);
                    $element
                        .data("title", "") // Clear the title - there is no error associated anymore
                        .removeClass("error")
                        .tooltip("destroy");
                });
                // Create new tooltips for invalid elements
                $.each(errorList, function (index, error) {
                    log.warn(error);
                    const $element = $(error.element);
                    $element
                        .tooltip("destroy") // Destroy any pre-existing tooltip so we can repopulate with new tooltip content
                        .data("title", error.message)
                        .addClass("error")
                        .tooltip("show"); // Create a new tooltip based on the error messsage we just set in the title
                });
            },
            submitHandler: function (form) {
                form.submit();
            },
            highlight: function (element) {
                $(element)
                    .closest('.form-group')
                    .removeClass('success')
                    .addClass('has-error');
            },
            success: function (element) {
                element.addClass('valid')
                    .closest('.form-group')
                    .removeClass('error')
                    .addClass('has-success');
            }
        });

        if ($('.nav-tabs').length > 0) { // if .nav-tabs exists
            const hashtag = window.location.hash;
            if (hashtag !== '') {
                $('.nav-tabs > li').removeClass('active');
                $('.nav-tabs > li > a[href="' + hashtag + '"]').parent('li').addClass('active');
                $('.tab-content > div').removeClass('active');
                $(hashtag).addClass('active');
            }
        }

    }
}
