import BaseJQueryPage from "./BaseJQueryPage";
import initialiseApplication from "../Init";

/**
 */
export default class OrganisationList extends BaseJQueryPage {
    // TODO: add code here
}


